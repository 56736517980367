
					@import './src/sass/variables';
					@import './src/sass/mixins';
				


















































































header::v-deep {
	b {
		font-weight: $font__bold;
	}
}
ul {
	list-style-type: none;
	padding-left: 0;
}

.bool-input {
	margin-bottom: 1rem;
	cursor: pointer;

	&:hover {
		color: $color__link;
	}
}

@include breakpoint-min('medium') {
	ul.columns {
		columns: 2;
	}
}

@include breakpoint-min('large') {
	ul.columns {
		columns: 3;
	}
}
